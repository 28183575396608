var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { businessService } from "~/config/server";
import { NetService } from "~/utils/net.service";
import { Inject } from "~/core/decorator";
var RepayService = /** @class */ (function () {
    function RepayService() {
    }
    /**
     * 行调业绩列表
     */
    RepayService.prototype.findGrades = function (data, sort, page, loading) {
        return this.netService.send({
            server: businessService.repayController.findGrades,
            data: data,
            page: page,
            sort: sort,
            loading: loading,
        });
    };
    /**
     * 新增行调业绩列表
     */
    RepayService.prototype.addGrades = function (data, loading) {
        return this.netService.send({
            server: businessService.repayController.addGrades,
            data: data,
            loading: loading,
        });
    };
    /**
     * 更新行调业绩列表
     */
    RepayService.prototype.updateGrades = function (data, loading) {
        return this.netService.send({
            server: businessService.repayController.updateGrades,
            data: data,
            loading: loading,
        });
    };
    /**
     * 删除行调业绩列表
     */
    RepayService.prototype.delGrades = function (data, loading) {
        return this.netService.send({
            server: businessService.repayController.delGrades,
            data: data,
            loading: loading,
        });
    };
    /**
     * 导出行调业绩列表
     */
    RepayService.prototype.exportGrades = function (data, loading) {
        return this.netService.send({
            server: businessService.repayController.exportGrades,
            data: data,
            loading: loading,
        });
    };
    __decorate([
        Inject(NetService)
    ], RepayService.prototype, "netService", void 0);
    return RepayService;
}());
export { RepayService };
