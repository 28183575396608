var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from "vue";
import Component from "vue-class-component";
import FilterCollection from "~/components/collection-manage/all-case/filter-collection.vue";
import FollowAllQuery from "~/components/collection-manage/follow-all-query.vue";
import { Layout, Dependencies } from "~/core/decorator";
import { State, namespace } from "vuex-class";
import DataForm from "~/components/common/data-form.vue";
import DataBox from "~/components/common/data-box.vue";
import { PageService } from "~/utils/page.service";
import { SortService } from "~/utils/sort.service";
import NumberRange from "~/components/common/number-range.vue";
import PrincipalBatch from "~/components/common-business/principal-batch.vue";
import { RepayService } from "~/services/business-service/repay.service";
import ftRequest from "~/utils/ft.request";
import { UploadFileService } from "~/services/common-service/upload-file.service";
var collectionManageModule = namespace("collection-manage");
var DetentionCase = /** @class */ (function (_super) {
    __extends(DetentionCase, _super);
    function DetentionCase() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.loading = {
            state: false,
        };
        _this.yesOrNo = [
            {
                text: "是",
            },
            {
                text: "否",
            },
        ];
        _this.fileList = [];
        _this.caseSourceOption = [
            {
                text: "唯品",
            },
            {
                text: "青年优品",
            },
            {
                text: "新网M1-3",
            },
            {
                text: "新网M12+",
            },
            {
                text: "美团金融M3",
            },
            {
                text: "招联4手",
            },
            {
                text: "招联诉前",
            },
            {
                text: "招联撤诉",
            },
            {
                text: "厦门分行未诉讼",
            },
            {
                text: "厦门分行未诉讼1",
            },
            {
                text: "福州分行未诉讼",
            },
            {
                text: "福州分行未诉讼1",
            },
            {
                text: "福州分行撤诉",
            },
            {
                text: "厦门分行撤诉",
            },
            {
                text: "漳州分行",
            },
            {
                text: "珠海分行",
            },
            {
                text: "小米金融",
            },
        ];
        _this.queryParamsModel = {
            loanName: "",
            loanUserId: "",
            weiAnDate: "",
            loanContractId: "",
            idNumber: "",
            caseSource: "",
            weiAnAmount: "",
            repayAmount: "",
            overdueGrade: "",
            inputDate: "",
            realRepayDate: "",
            shiFouDaiChang: "",
            repayType: "",
            settleType: "",
            tiaoJieYuan: "",
            isNewUser: "",
            overdueDays: "",
            platform: "",
            pinZhengPath: "",
        };
        _this.dataSet = [];
        _this.dialog = {
            detail: false,
            show: false,
        };
        _this.formModel = {
            id: undefined,
            loanName: "",
            loanUserId: "",
            weiAnDate: "",
            loanContractId: "",
            idNumber: "",
            caseSource: "",
            weiAnAmount: "",
            repayAmount: "",
            overdueGrade: "",
            inputDate: "",
            realRepayDate: "",
            shiFouDaiChang: "",
            repayType: "",
            settleType: "",
            tiaoJieYuan: "",
            isNewUser: "",
            overdueDays: "",
            platform: "",
            pinZhengPath: ""
        };
        _this.rules = {
            loanName: [{ required: true, message: "请输入客户姓名", trigger: "blur" }],
            loanId: [{ required: true, message: "请输入客户编号", trigger: "blur" }],
            weiAnDate: [{ required: true, message: "请选择委案日期", trigger: "change" }],
            weiAnAmount: [{ required: true, message: "请输入委案金额", trigger: "blur" }],
            repayAmount: [{ required: true, message: "请输入还款金额", trigger: "blur" }],
            caseSource: [{ required: true, message: "请输入案源", trigger: "blur" }],
            tiaoJieYuan: [{ required: true, message: "请输入调解员", trigger: "blur" }],
            loanContractId: [{ required: true, message: "请输入借款合同编号", trigger: "blur" }],
            repayType: [{ required: true, message: "请选择还款方式", trigger: "change" }],
            settleType: [{ required: true, message: "请选择结清方式", trigger: "change" }],
            inputDate: [{ required: true, message: "请选择录入业绩时间", trigger: "change" }],
            realRepayDate: [{ required: true, message: "请选择时间还款时间", trigger: "change" }],
        };
        return _this;
    }
    DetentionCase.prototype.mounted = function () {
        this.refreshData();
    };
    DetentionCase.prototype.handleResetForm = function () {
        this.formModel = {};
        this.refreshData();
    };
    DetentionCase.prototype.handleRemove = function (file) {
        console.log(file);
        this.formModel.pinZhengPath = '';
    };
    ;
    DetentionCase.prototype.handleSuccess = function (file) {
        if (file.url) {
            this.$message.success('上传成功');
            this.formModel.pinZhengPath = file.url;
        }
    };
    ;
    DetentionCase.prototype.onSubmit = function () {
        var _this = this;
        var formModel = this.$refs["formModel"];
        formModel.validate(function (valid) {
            if (!valid)
                return false;
            if (_this.formModel.id !== undefined && _this.formModel.id !== "" && _this.formModel.id !== null) {
                _this.repayService.updateGrades(_this.formModel, _this.loading).subscribe(function (data) {
                    _this.$message.success("更新成功");
                    _this.dialog.show = false;
                    _this.formModel = {};
                    _this.refreshData();
                });
            }
            else {
                _this.repayService.addGrades(_this.formModel, _this.loading).subscribe(function (data) {
                    _this.$message.success("保存成功");
                    _this.dialog.show = false;
                    _this.formModel = {};
                    _this.refreshData();
                });
            }
        });
    };
    DetentionCase.prototype.showPic = function (url) {
        if (!url) {
            this.$message.warning('未上传凭证');
            return;
        }
        this.uploadFileService.getOssSignUrl(url).subscribe(function (data) {
            window.open(data);
        });
    };
    DetentionCase.prototype.del = function (id) {
        var _this = this;
        this.$confirm("确定删除该记录？", "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
        }).then(function () {
            _this.repayService.delGrades({ id: id }, _this.loading).subscribe(function (data) {
                _this.$message.success("删除成功");
                _this.refreshData();
            });
        });
    };
    DetentionCase.prototype.removeEmptyProperties = function (obj) {
        return Object.fromEntries(Object.entries(obj)
            .filter(function (_a) {
            var _ = _a[0], value = _a[1];
            return value !== null &&
                value !== undefined &&
                value !== "" &&
                (Array.isArray(value) ? value.length > 0 : true) &&
                (typeof value === "object" && !Array.isArray(value) ? Object.keys(value).length > 0 : true);
        }));
    };
    DetentionCase.prototype.refreshData = function () {
        var _this = this;
        this.repayService.findGrades(this.removeEmptyProperties(this.queryParamsModel), this.sortService, this.pageService, this.loading).subscribe(function (data) {
            _this.dataSet = data;
        });
    };
    DetentionCase.prototype.exportList = function () {
        var _this = this;
        this.loading.state = true;
        ftRequest.postJsonDownload("/business-service/api/gradesController/exportGrades", this.removeEmptyProperties(this.queryParamsModel), this.userToken).then(function (data) {
            _this.loading.state = false;
            var blob = data;
            // 创建一个链接元素
            var link = document.createElement("a");
            link.href = window.URL.createObjectURL(blob);
            link.download = "行调业绩表.xlsx";
            // 将链接元素添加到 DOM 并点击它
            document.body.appendChild(link);
            link.click();
            // 完成后移除链接元素
            document.body.removeChild(link);
            _this.$message.success("导出成功");
        }).catch(function (error) {
            console.log(error);
            _this.loading.state = false;
        });
    };
    __decorate([
        Dependencies(PageService)
    ], DetentionCase.prototype, "pageService", void 0);
    __decorate([
        Dependencies(SortService)
    ], DetentionCase.prototype, "sortService", void 0);
    __decorate([
        Dependencies(RepayService)
    ], DetentionCase.prototype, "repayService", void 0);
    __decorate([
        Dependencies(UploadFileService)
    ], DetentionCase.prototype, "uploadFileService", void 0);
    __decorate([
        State
    ], DetentionCase.prototype, "userToken", void 0);
    DetentionCase = __decorate([
        Layout("workspace"),
        Component({
            components: {
                PrincipalBatch: PrincipalBatch,
                DataForm: DataForm,
                DataBox: DataBox,
                FilterCollection: FilterCollection,
                FollowAllQuery: FollowAllQuery,
                NumberRange: NumberRange,
            },
        })
    ], DetentionCase);
    return DetentionCase;
}(Vue));
export default DetentionCase;
